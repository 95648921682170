import React, { useState }  from "react"
import queryString from 'query-string'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typer from "../components/typer"
import Settings from "../components/settings"

import { MODES } from '../util/constants'
import { LINE_OPTS } from '../util/presets'

const DEFAULT_WORDLIST = 'familiar300'

const query = queryString.parse(typeof window === 'object' ? window.location.search : '')

const { w } = query

const IndexPage = () => {
  const [expandSettings, setExpandSettings] = useState(false)
  const [numWords, setNumWords] = useState(50)
  const [timerSeconds, setTimerSeconds] = useState(60)
  const [mode, setMode] = useState(MODES.TIMED)
  const [showMetrics, setShowMetrics] = useState(false)
  const [lineOpts, setLineOpts] = useState({ ...LINE_OPTS.DEFAULT })
  const [experimental, setExperimental] = useState(false)
  const [wordlist, setWordlist] = useState(w || DEFAULT_WORDLIST) // eslint-disable-line no-unused-vars

  const toggleExpandSettings = () => setExpandSettings(!expandSettings)
  const toggleShowMetrics = () => setShowMetrics(!showMetrics)
  const toggleExperimental = () => setExperimental(!experimental)

  return (
      <Layout>
          <SEO 
            title="Typing Speed Practice for Programmers" 
          />
          <Typer
            numWords={numWords} 
            timerSeconds={timerSeconds}
            showDebug={showMetrics}
            mode={mode}
            lineOpts={lineOpts}
            toggleExperimentalMode={toggleExperimental}
            wordlist={wordlist}
          />
          <Settings
            expand={expandSettings}
            toggleExpand={toggleExpandSettings}
            showMetrics={showMetrics}
            toggleShowMetrics={toggleShowMetrics}
            experimentalMode={experimental}
            mode={mode}
            setMode={setMode}
            wordCount={numWords}
            setWordCount={setNumWords}
            time={timerSeconds}
            setTime={setTimerSeconds}
            lineOpts={lineOpts}
            setLineOpts={setLineOpts}
            wordlist={wordlist}
            setWordlist={setWordlist}
          />
      </Layout>
  )
}

export default IndexPage
