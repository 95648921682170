import './settings.css'
import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { FaCog } from 'react-icons/fa'

import { 
    MODES, 
    WORDCOUNT_OPTIONS,
    TIMED_OPTIONS,
    MODE_OPTIONS_LABEL,
    WORDLIST_VALUES
} from '../util/constants'

const Settings = props => {
    const { 
        expand, toggleExpand, setMode, mode, setWordCount, wordCount, 
        setTime, time, showMetrics, toggleShowMetrics, lineOpts, setLineOpts,
        experimentalMode, wordlist, setWordlist
    } = props

    const { numbers, alphas, symbols } = lineOpts

    const showOptionsForMode = currentMode => {
        if (currentMode === MODES.WORDCOUNT) {
            return (
                <div className={'mode-options-list'}>
                    {_.map(WORDCOUNT_OPTIONS, wo => (
                        <div 
                            key={`wc-${wo.value}`}
                            className={classNames({
                                'mode-option': true,
                                'selected': wo.value === wordCount
                            })}
                            onClick={() => setWordCount(wo.value)}
                        >
                            {wo.label}
                        </div>
                    ))}
                </div>
            )
        } else if (currentMode === MODES.TIMED) {
            return (
                <div className={'mode-options-list'}>
                    {_.map(TIMED_OPTIONS, wo => (
                        <div 
                            key={`wc-${wo.value}`}
                            className={classNames({
                                'mode-option': true,
                                'selected': wo.value === time
                            })}
                            onClick={() => setTime(wo.value)}
                        >
                            {wo.label}
                        </div>
                    ))}
                </div>
            )
        }
    }

    const renderSettings = expand => {
        if (!expand) {
            return null
        }

        return (
            <React.Fragment>
                <div id="settings-panel">
                    <div className="display-label">Display:</div>
                    <div className="global-settings-row">
                        <div 
                            className={classNames({
                                'settings-button': true,
                                'selected': showMetrics
                            })}
                            onClick={toggleShowMetrics}
                        >
                            Show metrics
                        </div>
                    </div>
                    <div className="display-label">Text options:</div>
                    <div className="global-settings-row">
                        {experimentalMode && <div 
                            className={classNames({
                                'settings-button': true,
                                'selected': !!alphas
                            })}
                            onClick={() => setLineOpts({ ...lineOpts, alphas: !alphas })}
                        >
                            Alphas
                        </div>}
                        <div 
                            className={classNames({
                                'settings-button': true,
                                'selected': !!numbers
                            })}
                            onClick={() => setLineOpts({ ...lineOpts, numbers: !numbers })}
                        >
                            Digits
                        </div>
                        <div 
                            className={classNames({
                                'settings-button': true,
                                'selected': !!symbols
                            })}
                            onClick={() => setLineOpts({ ...lineOpts, symbols: !symbols })}
                        >
                            Symbols
                        </div>
                    </div>
                    <div className="mode-options-row">
                        <div className="mode-options-column">
                            <div id="mode-options-label">Mode:</div>
                            <div className={'mode-options-list'}>
                                <div 
                                    key={`mode-timed`}
                                    className={classNames({
                                        'mode-option': true,
                                        'selected': mode === MODES.TIMED
                                    })}
                                    onClick={() => mode === MODES.TIMED ? null : setMode(MODES.TIMED)}
                                >
                                    Timed
                                </div>
                                <div 
                                    key={`mode-wordcount`}
                                    className={classNames({
                                        'mode-option': true,
                                        'selected': mode === MODES.WORDCOUNT
                                    })}
                                    onClick={() => mode === MODES.WORDCOUNT ? null : setMode(MODES.WORDCOUNT)}
                                >
                                    Word Count
                                </div>
                            </div>
                        </div>
                        <div className="mode-options-column">
                            <div id="mode-options-label">{MODE_OPTIONS_LABEL[mode]}</div>
                            {showOptionsForMode(mode)}
                        </div>
                    </div>
                    <div className="display-label">Word List:</div>
                    <div className="global-settings-row">
                        {_.map(WORDLIST_VALUES, wlv => (
                            <div 
                                key={wlv.value}
                                className={classNames({
                                    'settings-button': true,
                                    'selected': wlv.value === wordlist
                                })}
                                onClick={() => wlv.value === wordlist ? null : setWordlist(wlv.value)}
                            >
                                {wlv.label}
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div id="settings-container">
            <div id="toggle-row">
                <button onClick={toggleExpand}>SETTINGS&nbsp;<FaCog /></button>
            </div>
            {renderSettings(expand)}
        </div>
    )
}

export default Settings