export const MODES = {
    WORDCOUNT: 'wordcount',
    TIMED: 'timed'
}

export const MODE_OPTIONS_LABEL = {
    [MODES.WORDCOUNT]: 'Total words:',
    [MODES.TIMED]: 'Time:'
}

export const WORDCOUNT_OPTIONS = [
    {
        label: '25',
        value: 25
    },
    {
        label: '50',
        value: 50
    },
    {
        label: '100',
        value: 100
    },
    {
        label: '250',
        value: 250
    },
    {
        label: '500',
        value: 500
    }
]

export const TIMED_OPTIONS = [
    {
        label: '0:30',
        value: 30
    },
    {
        label: '1:00',
        value: 60
    },
    {
        label: '2:00',
        value: 120
    },
    {
        label: '5:00',
        value: 300
    },
    {
        label: '10:00',
        value: 600
    }
]

export const ERROR_THRESHOLD = 0.4

export const MIN_DIGITS_NUMWORD = 2
export const MAX_DIGITS_NUMWORD = 6

export const MIN_DIGITS_ADDNUMS = 1
export const MAX_DIGITS_ADDNUMS = 4

export const PROBABILITY_NUMWORD = 5
export const PROBABILITY_CORPUSWORD = 95
export const PROBABILITY_HEXPAIR = 2
export const PROBABILITY_HEXCOLOR = 3

export const PROBABILITY_NUMS_NOOP = 85
export const PROBABILITY_ADDNUMS = 15

export const WORD_TYPE = {
    REGULAR: 'regular',
    NUMWORD: 'numword',
    HEXWORD: 'hexword'
}

export const SYMBOL_TYPE = {
    BANG: '!',
    AT: '@',
    HASH: '#',
    PERCENT: '%',
    CARET: '^',
    AMPERSAND: '&',
    ASTERISK: '*',
    PARENS: 'parens',
    MINUS: '-',
    UNDERSCORE: '_',
    PLUS: '+',
    EQUALS: '=',
    BACKTICK: '`',
    TILDE: '~',
    BRACKETS: 'brackets',
    GT_LT: 'gtlt',
    CURLIES: 'curlies',
    BACKSLASH: '\\',
    PIPE: '|',
    SEMICOLON: ';',
    COLON: ':',
    SINGLE_QUOTE: '\'',
    DOUBLE_QUOTE: '"',
    COMMA: ',',
    PERIOD: '.',
    SLASH: '/',
    QUESTION_MARK: '?'
}

export const PREFIX_SYMBOL_BLOCKERS = [
    'ampersand',
    'at',
    'bang', 
    'bangbang',
    'caret',
    'dunder',
    'hash',
    'neg', 
    'pre$',
    'pos', 
    'star', 
    'under'
]

export const SYMBOL_FN_PROBABILITY = {
    NOOP: 50,
    BANG: 5,
    BANGBANG: 5,
    AT: 5,
    HASH: 5
}

export const HEX_CHARACTERS = [
    '0', '1', '2', '3', '4', '5', '6', '7',
    '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'
]

export const SECRET_CODE_CLICKS = 10

export const PINKY_CHARS = [
    'q', 'a', 'z', 'p', ';', ':', 
    '/', '?', '\'', '"', '[', ']',
    '{', '}', '|', '\\', '0', ')',
    '-', '_', '=', '+'
]

export const WORDLIST_VALUES = [
    {
        label: 'Familiar 300 (default)',
        value: 'familiar300'
    },
    {
        label: 'English Top 1000',
        value: 'english1000'
    },
    {
        label: 'Pinky Practice',
        value: 'pinkies'
    },
    {
        label: 'Pinkies (not A-words)',
        value: 'pinkies_no_a'
    },
    {
        label: 'ZX',
        value: 'zx'
    },
    {
        label: 'ZXCV',
        value: 'zxcv'
    },
    {
        label: 'Left Hand',
        value: 'left_hand'
    },
    {
        label: 'Right Hand',
        value: 'right_hand'
    }
]