import './typer.css'
import React, { useState, useEffect, useRef } from 'react'
import { FaUndoAlt } from 'react-icons/fa'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import _ from 'lodash'
import classNames from 'classnames'

import { MODES, SECRET_CODE_CLICKS } from '../util/constants'

import worker from '../workers/wordlist.worker.js'

let wordWorker = typeof window === 'object' && new worker()

const zerofilled = n => ('00'+n).slice(-2)
const formatTime = (minutes, seconds) => `${minutes}:${zerofilled(seconds)}`

const Word = ({ children, current = false, error = false, success = false }) => (
    <span className={classNames({
        'word': true,
        current,
        error,
        success
    })}>
        {children}
    </span>
)

function useInterval(callback, delay) {
    const savedCallback = useRef()
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback])
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

export default props => {
    const { 
        numWords, timerSeconds, showDebug, mode, lineOpts, toggleExperimentalMode, wordlist
    } = props
    const textInput = useRef(null)

    const [lineIndex, setLineIndex] = useState(0)
    const [wordIndex, setWordIndex] = useState(0)

    const [experimentalModeCounter, setExperimentalModeCounter] = useState(0)

    const [keystrokes, setKeystrokes] = useState(0)
    const [idealKeystrokes, setIdealKeystrokes] = useState(0)
    const [typedWords, setTypedWords] = useState(0)
    const [erroredWords, setErroredWords] = useState(0)
    const [finished, setFinished] = useState(false)
    const [running, setRunning] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [finishTime, setFinishTime] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [timeElapsed, setTimeElapsed] = useState(0)
    const [intervalDelay, setIntervalDelay] = useState(null)

    const [lineBuffer, setLineBuffer] = useState([])
    const [typedWordsBuffer, setTypedWordsBuffer] = useState([])

    const [finalStats, setFinalStats] = useState(null)

    const currentLine = lineBuffer && lineBuffer.length ? lineBuffer[lineIndex] : []
    

    const [currentLineErrors, setCurrentLineErrors] = useState(_.map(currentLine, () => false))

    const nextLine = lineIndex + 1 < lineBuffer.length ? lineBuffer[lineIndex+1] : []
    const currentWord = currentLine && currentLine.length ? currentLine[wordIndex] : ''
    const currentWordError = currentLineErrors && currentLineErrors.length ? currentLineErrors[wordIndex] : []

    const timerMilliseconds = timerSeconds * 1000

    const timeElapsedSeconds = timeElapsed ? timeElapsed / 1000.0 : 0
    const timeElapsedMinutes = timeElapsedSeconds ? timeElapsedSeconds / 60.0 : 0
    const displaySecondsElapsed = Math.floor(timeElapsedSeconds % 60)
    const displayMinutesElapsed = Math.floor(timeElapsedMinutes)

    const timeRemaining = timerMilliseconds - timeElapsed
    const timeRemainingSeconds = _.clamp(mode === MODES.TIMED ? timeRemaining / 1000.0 : 0, 0, Infinity)
    const timeRemainingMinutes = _.clamp(mode === MODES.TIMED ? timeRemainingSeconds / 60.0 : 0, 0, Infinity)
    const displayTimeRemainingSeconds = Math.floor(timeRemainingSeconds % 60)
    const displayTimeRemainingMinutes = Math.floor(timeRemainingMinutes)

    const calculateFinalStats = () => {
        if (!finishTime) {
            setFinalStats(null)
            return
        }
        textInput.current.value = ''
        const inputString = typedWordsBuffer.join(' ')
        const flattenedLinesBuffer = _.flatten(lineBuffer)

        const totalTimeMilliseconds = differenceInMilliseconds(finishTime, startTime)
        const totalTimeMinutes = totalTimeMilliseconds / (60.0 * 1000.0)

        const totalTypedCharacters = inputString.length
        const numErrors = _.reduce(flattenedLinesBuffer, (acc, word, index) => {
            if (word !== typedWordsBuffer[index] && !!typedWordsBuffer[index]) {
                return acc + 1
            }
            return acc
        }, 0)

        const technicallyWords = (totalTypedCharacters / 5.0)
        const netWpm = _.clamp((technicallyWords - numErrors) / totalTimeMinutes, 0, Infinity)
        const grossWpm = _.clamp(technicallyWords / totalTimeMinutes, 0, Infinity)
        const accuracy = netWpm === 0.0 || grossWpm === 0.0 ? 0.0 : (netWpm/grossWpm) * 100
        console.log(`numErrors: ${numErrors}; erroredWords: ${erroredWords}`)
        console.log(`netWpm: ${netWpm}; grossWpm: ${grossWpm}; accuracy: ${accuracy}`)
        setFinalStats({
            grossWpm,
            netWpm,
            accuracy
        })
    }

    useEffect(calculateFinalStats, [finishTime])

    const handleSecretExperimentalCounter = () => {
        if (experimentalModeCounter < SECRET_CODE_CLICKS) {
            setExperimentalModeCounter(experimentalModeCounter + 1)
        } else {
            toggleExperimentalMode()
            setExperimentalModeCounter(0)
        }
    }

    useInterval(() => {
        const timeElapsed = startTime ? differenceInMilliseconds(new Date(), startTime) : 0
        setTimeElapsed(timeElapsed) // just something to keep the state refreshing
        if (mode === MODES.TIMED) {
            if (timeElapsed && timeElapsed >= timerMilliseconds) {
                stopGame()
            }
        }
    }, intervalDelay)

    const init = async () => {
        reset()
    }

    const startGame = () => {
        setStartTime(new Date())
        setFinished(false)
        setRunning(true)
        setIntervalDelay(250)
    }

    const stopGame = () => {
        setFinishTime(new Date())
        setFinished(true)
        setRunning(false)
        setIntervalDelay(null)
    }

    const reset = async () => {
        let resetWords = numWords
        if (mode === MODES.TIMED) {
            resetWords = Math.floor(220 * (timerSeconds / 60))
        }
        const newLineBuffer = await wordWorker.fillLineBufferWithNWords(resetWords, wordlist, lineOpts)
        textInput.current.value = ''
        setInitialized(true)
        setLineBuffer(newLineBuffer)
        setLineIndex(0)
        setWordIndex(0)
        setKeystrokes(0)
        setIdealKeystrokes(0)
        setTypedWords(0)
        setErroredWords(0)
        setFinished(false)
        setRunning(false)
        setStartTime(null)
        setFinishTime(null)
        setTimeElapsed(0)
        setTypedWordsBuffer([])
        setFinalStats(null)
        textInput.current.focus()
    }

    const handleSettingsChange = () => {
        reset()
    }

    useEffect(() => {
        setCurrentLineErrors(_.map(lineBuffer[lineIndex], () => false))
    }, [lineBuffer, lineIndex])

    useEffect(handleSettingsChange, [numWords, mode, lineOpts, timerSeconds, wordlist])

    const advanceLine = () => {
        if (lineIndex < lineBuffer.length - 1) {
            const newLineIndex = lineIndex + 1
            setLineIndex(newLineIndex)
            setWordIndex(0)
            setCurrentLineErrors(_.map(lineBuffer[newLineIndex], () => false))
        } else {
            stopGame()
        }
    }

    const advanceWord = (inputWord, currentWord) => {
        const currentWordKeystrokes = currentWord.length + 1 // 1 for the space bar
        setIdealKeystrokes(idealKeystrokes + currentWordKeystrokes)
        setTypedWords(typedWords + 1)
        setTypedWordsBuffer(_.concat(typedWordsBuffer, inputWord))
        if (inputWord && currentWord && inputWord !== currentWord) {
            setErroredWords(erroredWords + 1)
        }
        if (wordIndex < currentLine.length - 1) {
            setWordIndex(wordIndex + 1)
        } else if (mode === MODES.WORDCOUNT && 
            lineIndex === lineBuffer.length - 1 && // Check if we are on the last word
            wordIndex === currentLine.length - 1) {
            stopGame()
        } else {
            advanceLine()
        }
    }

    const processKeyPress = e => {
        if (finished) {
            e.preventDefault()
        } else {
            if (e.shiftKey) {
                setKeystrokes(keystrokes + 2)
            } else {
                setKeystrokes(keystrokes + 1)
            }
        }

        const isSpaceBar = e.key === ' '
        if (isSpaceBar) {
            e.preventDefault()
            const inputWord = textInput.current.value

            if (!inputWord) {
                return
            }

            if (inputWord !== currentWord) {
                if (!currentWordError) {
                    const newLineErrors = [...currentLineErrors]
                    newLineErrors[wordIndex] = true
                    setCurrentLineErrors(newLineErrors)
                }
            }
            advanceWord(inputWord, currentWord)
            textInput.current.value = ''
        }
    }

    const handleInput = e => {
        const inputWord = e.target.value
        if (!running && !finished) {
            startGame()
        }
        if (inputWord && inputWord.length && !_.startsWith(currentWord, inputWord)) {
            if (!currentWordError) {
                const newLineErrors = [...currentLineErrors]
                newLineErrors[wordIndex] = true
                setCurrentLineErrors(newLineErrors)
            }
        } else {
            if (currentWordError) {
                const newLineErrors = [...currentLineErrors]
                newLineErrors[wordIndex] = false
                setCurrentLineErrors(newLineErrors)
            }
        }
    }

    if (!initialized) {
        init()
    }

    const remainingWords = MODES.WORDCOUNT ? numWords - typedWords : 0
    const displayTimeRemaining = formatTime(displayTimeRemainingMinutes, displayTimeRemainingSeconds)

    const renderWordlist = () => (
        <React.Fragment>
            <div className="line">{_.map(currentLine, (w, idx) => {
                const isCurrent = idx === wordIndex
                const isError = currentLineErrors[idx]
                const isSuccess = !isError && ((idx < wordIndex) || (idx === wordIndex && finished))
                return (
                    <Word 
                        current={isCurrent} 
                        error={isError} 
                        success={isSuccess}
                        key={idx}>
                        {w}
                    </Word>
                    )
            })
            }</div>
            <div className="line">{_.map(nextLine, (w, idx) => (<Word key={idx}>{w}</Word>))}</div>
        </React.Fragment>
    )

    const renderDebug = show => {
        if (!show) return null
        return (
            <React.Fragment>
                <span>{`Keystrokes: ${keystrokes}/${idealKeystrokes}`}</span>
                <span>{`Words: ${typedWords - erroredWords}/${typedWords}`}</span>
                <span>{formatTime(displayMinutesElapsed, displaySecondsElapsed)}</span>
            </React.Fragment>
        )
    }

    const renderFinalStats = () => {
        return (
            <React.Fragment>
                <div className="final-stats-row">
                    <div className="final-stats-column">
                        <div id="final-stats-wpm-score">{Math.round(finalStats.netWpm)}</div>
                        <div id="final-stats-wpm-label">WPM</div>
                    </div>
                    <div className="final-stats-column">
                        <div id="final-stats-acc-score">{`${finalStats.accuracy.toFixed(1)}%`}</div>
                        <div id="final-stats-acc-label">ACCURACY</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div id="typer-container">
            <div id="word-list" className={classNames({'final-score': !!finalStats})}>
                {!finished && !finalStats && renderWordlist()}
                {!!finalStats && renderFinalStats()}
            </div>
            <div id="control-row">
                <input 
                    id="typer-input"
                    className="control-column"
                    type="text" 
                    onChange={handleInput} 
                    onKeyPress={processKeyPress}
                    ref={textInput}
                />
                <div id="typer-remaining" className="control-column" onClick={handleSecretExperimentalCounter}>
                    <span className="control-text">{mode === MODES.TIMED ? displayTimeRemaining : remainingWords}</span>
                </div>
                <button id="typer-reset" className="control-column" onClick={reset}><FaUndoAlt/></button>
            </div>
            <div id="debug-row">
                {renderDebug(showDebug)}
            </div>
        </div>
    )
}